import React,{useState} from 'react';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import './DayNutrimentLine.scss';

type DayNutrimentLineProps = {
  label: string;
  valueEated: number;       // rellement consommee, entier
  valueRecommended: number;
  valueEatedString: string;
  valueRecommendedString: string;
}

function DayNutrimentLine(props:DayNutrimentLineProps) {
  // props
  let {label,valueEated,valueRecommended,valueEatedString,valueRecommendedString} = props;
  
  // states
  const [details, setDetails] = useState(false);

  function toggleAccordion() {
    setDetails(!details);
  }

  // console.log({
  //   label: label,
  //   valueEated: valueEated,
  //   valueRecommended: valueRecommended,
  //   valueEatedString: valueEatedString,
  //   valueRecommendedString: valueRecommendedString
  // });

  if(valueEated === undefined || valueEated === Number.NaN || valueRecommended === undefined){
    return (
      <div className="ui-dayNutrimentLine">
        <ListItem>
          <div className="label-wrapper">
            <Typography sx={{ fontSize: 14, lineHeight: 1.2, fontWeight: 500, fontStyle: 'italic' }} color="text.secondary" gutterBottom>{label}</Typography>
          </div>
          <div className="progress-wrapper">
            <LinearProgress variant="determinate" color={'inherit'} value={0} sx={{height:'8px'}} style={{opacity:'0.5'}}/>
            <LinearProgress variant="determinate" color={'inherit'} value={0} sx={{height:'8px'}} style={{position:'absolute',top:'0',left:'0',width:'100%',maxWidth:50+'%'}}/>  
          </div>
        </ListItem>
      </div>
    );
  }

  const MAX = 100;
  const value = valueEated * MAX / valueRecommended;

  let alertState = undefined;
  if(value < 80) alertState = 'primary';
  if(value >= 80 && value <= 120) alertState = 'success';
  if(value > 120) alertState = 'error';

  // valeurs rapportees à 150 pour les depassements (choix arbitraire)
  const NEW_MAX = 150;
  const new_value = value * MAX / NEW_MAX;
  const new_max_relative = MAX * MAX / NEW_MAX;

  // valeur pour la 1re progressbar 
  const first_value = value > 100 ? 100 : value;

  return (
    <div className="ui-dayNutrimentLine">
      <ListItem secondaryAction={
        <IconButton size="small" color="primary" onClick={toggleAccordion} style={{cursor:'pointer'}}><InfoIcon /></IconButton>
      }>
        <div className="label-wrapper">
          <Typography className="typo-values" color="text.secondary">{label}</Typography>
        </div>
        <div className="progress-wrapper">
          <LinearProgress variant="determinate" color={alertState} value={new_value} sx={{height:'8px'}} style={{opacity:'0.5'}}/>
          <LinearProgress variant="determinate" color={alertState} value={first_value} sx={{height:'8px'}} style={{position:'absolute',top:'0',left:'0',width:'100%',maxWidth:new_max_relative+'%'}}/>  
        </div>
      </ListItem>
      <Collapse in={details} timeout="auto" unmountOnExit>
        <div className="values-wrapper">
          <Typography className="typo-values" color="text.secondary">{valueEatedString} consommé / {valueRecommendedString} conseillé, soit {Math.round(value)}%</Typography>
        </div>
      </Collapse>
    </div>
  );
}

export default DayNutrimentLine;
