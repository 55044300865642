import S_App          from   '../../services/S_App';
import Papa           from   'papaparse';

interface I_S_Ciqual {
  PATH_FILE_CSV: string;
  ciquals: any[];

  fetch(cb): any;
  formatValue(v:any): number;
};

/**
 * @namespace
 * @folder Services
 * @type {Object}
 */
let S_Ciqual: I_S_Ciqual = {
  /**
   * @memberof S_Ciqual
   * @property {string} PATH_FILE_CSV Chemin du fichier Ciqual csv
   */
   PATH_FILE_CSV : '/Table-Ciqual-2020_FR_2020-07-07.csv',

  /** Liste des objets */
  ciquals : [],

  /**
   * Récupère les données en localStorage, et contruit la liste des barcodes
   * @param cb Fonction de callback
   */
  fetch: (cb) => {

    // reset
    S_Ciqual.ciquals = [];

    // Fetch
    fetch(S_Ciqual.PATH_FILE_CSV).then(function(v) {
      if(S_App.isLogDebug) console.log('-- S_Ciqual.tsx -- fetch() v',v);
      v.text().then(function(v){
        const jsonDatas = Papa.parse(v,{header:true});
        if(S_App.isLogDebug) console.log('-- S_Ciqual.tsx -- fetch() result',jsonDatas.data);
        S_Ciqual.ciquals = [...jsonDatas.data];
      });
    });
    if(S_App.isLogDebug) console.log('-- S_Ciqual.tsx -- fetch() array',S_Ciqual.ciquals);

    if(!!cb) cb();
  },

  formatValue(v):number{
    if(v===undefined)
      return 0;
    if('traces'===v || '-'===v)
      return 0;
    return parseFloat((v+'').replaceAll(',','.').replaceAll(' ','').replaceAll('<',''));
  }
  
};
export default S_Ciqual;