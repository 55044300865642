import React,{useState,useEffect} from 'react';

import Typography from '@mui/material/Typography';

import './DataNutrimentLine.scss';

type DataNutrimentLineProps = {
  label: string;
  valueRelative: string;    // pour 100g
  valueEated: string;       // rellement consommee
}

export default function DataNutrimentLine(props:DataNutrimentLineProps) {
  // props
  let {label,valueRelative,valueEated} = props;
  
  // states


  return (
    <div className="ui-dataNutrimentLine">
      <Typography className="typo-label" color="text.secondary" gutterBottom>{label}</Typography>
      <Typography className="typo-ref" color="text.secondary" gutterBottom>{valueRelative}</Typography>
      <Typography className="typo-value" color="text.secondary" gutterBottom>{valueEated}</Typography>
    </div>
  );
};
