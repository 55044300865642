import S_Date   from   './S_Date';
let S_App = {
  STORAGE_KEY_PREFIX : 'ajr',
  isLogDebug: process.env.REACT_APP_ENV !== 'production',

  getByID: (array,id:number|string) => {
    if(id===undefined){
      // console.log('   id undefined',array,id);
      return;
    }
    if(!array || array.length===0){
      // console.log('   array empty',array,id);
      return;
    }
    for(var i = 0; i < array.length; i++){
      if(typeof id === 'number'){
        // console.log('   id is number',array,id);
        if(array[i].id !== id)
          continue;
        return array[i];
      }else if(typeof id === 'string'){
        // console.log('   id is string',array,id);
        if(array[i].id !== parseInt(id))
          continue;
        return array[i];
      }
    }
    return;
  },

  getIndexOfByID(array,id:number){
    if(id===undefined)
      return;
    let foundIndex = -1;
    for(var i = 0; i < array.length; i++){
      if(array[i].id !== id)
        continue;
      foundIndex = i;
      break;
    }
    return foundIndex;
  },

  // filters : [{attribute:xxxx,value:xxx}]
  getByFilters: (array,filters) => {
    if(!array)
      return;
    if(array.length===0)
      return array;
    // console.log('getByFilters - ',filters);
    if(!filters || filters.length===0)
      return array;
    let filtered = [];
    for(var i = 0; i < array.length; i++){
      let item = array[i];
      // console.log('getByFilters - ',item);
      let validateAllFilters = true;
      for(var f = 0;f < filters.length; f++){
        let filter = filters[f];
        // console.log('getByFilters - ',filter,filter.attribute,filter.value);
        if(filter.value === false){
          if(!item[filter.attribute] || item[filter.attribute] === false)
            continue;
          validateAllFilters = false;
          break;
        }else{
          if(item[filter.attribute] === filter.value)
            continue;
          validateAllFilters = false;
          break;
        }
      }
      if(!validateAllFilters)
        continue;
      filtered.push(item);
    }
    return filtered;
  },



  replaceBr(text:string){
    if(!text)
      return undefined;
    let newText = text.replace('\n\n','<br/><br/>').replace('\n','<br/>');
    newText = newText.replace(/(<? *script)/gi, 'illegalscript');
    return newText;
  },
  sortByOrder(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.order !== undefined ? a.order : a.id;
      let orderB = b.order !== undefined ? b.order : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },
  sortByLabel(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.label_fr !== undefined ? a.label_fr : a.id;
      let orderB = b.label_fr !== undefined ? b.label_fr : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },
  groupByKey(array, key) {
    return array.reduce((hash, obj) => {
       if(obj[key] === undefined) return hash; 
       return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
    }, {})
  }
    
  
};
export default S_App;