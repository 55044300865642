import React,{useState,useEffect} from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import S_Date        from '../../../services/S_Date';
import S_Favorites   from '../../services/S_Favorites';
import S_Ciqual      from '../../services/S_Ciqual';
import DataNutrimentLine from '../atoms/DataNutrimentLine';

// import './Day.scss';

type DataProps = {
  data: any;
  deleteData: any;
}

function Data(props:DataProps) {
  // props
  let {data,deleteData} = props;
  const code = data.barcode!==undefined ? data.barcode : data.ciqualcode;
  const barcodeAsNumber = typeof code === 'number' ? code : parseInt(code);
  const isCiqual = data.ciqualcode!==undefined && data.ciqualcode!==null && data.ciqualcode!==Number.NaN;
  const isOpenFoodFact = data.barcode!==undefined && data.barcode!==null && data.barcode!==Number.NaN;
  // states
  const [details, setDetails] = useState(false);
  const [isBarcodeInFavorites, setIsBarcodeInFavorites] = useState(S_Favorites.favorites_barcodes.indexOf(barcodeAsNumber)===-1);

  function toggleAccordion() {
    setDetails(!details);
  }
  function addToFavorites(){
    const code = data.barcode!==undefined ? data.barcode : data.ciqualcode;
    let barcodeAsNumber = typeof code === 'number' ? code : parseInt(code);
    S_Favorites.add(barcodeAsNumber,() => {
      setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(barcodeAsNumber)===-1);
    });
  }
  function removeFromFavorites(){
    const code = data.barcode!==undefined ? data.barcode : data.ciqualcode;
    let barcodeAsNumber = typeof code === 'number' ? code : parseInt(code);
    S_Favorites.delete(barcodeAsNumber,() => {
      setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(barcodeAsNumber)===-1);
    });
  }

  // if(isCiqual){
    console.log(data,isCiqual,isOpenFoodFact);
    // console.log(Object.keys(data.product));
  // }
    

  return (
    <div>
      <ListItem secondaryAction={
        <IconButton size="small" color="primary" onClick={() => deleteData(data)}><DeleteIcon /></IconButton>
      }>
        <ListItemAvatar>
          {isCiqual ? (
            <Avatar variant="rounded" onClick={toggleAccordion} style={{cursor: 'pointer'}}>Ci</Avatar>
          ):null}
          {isOpenFoodFact ? (
            <>
            {!!data.product && !!data.product.image_small_url ? (
              <Avatar alt="" src={`${data.product.image_small_url}`} variant="rounded" onClick={toggleAccordion} style={{cursor: 'pointer'}}/>
            ):(
              <Avatar variant="rounded" onClick={toggleAccordion} style={{cursor: 'pointer'}}><QuestionMarkIcon /></Avatar>
            )}
            </>
          ):null}
        </ListItemAvatar>
        <div style={{minWidth: 'calc( 100% - 88px )'}}>
          <div style={{display: 'flex'}}>
            {isCiqual ? (
              <div style={{paddingRight: '0.5rem'}}>
                <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: 1.2, cursor: 'pointer' }} onClick={toggleAccordion}>{!!data.product ? data.product.alim_nom_fr : '???'}</Typography>
                <Typography sx={{ fontSize: 12 }} color="text.secondary">Quantité : <Chip label={data.g + 'g'} variant="outlined" size="small" sx={{paddingTop: '2px',paddingBottom: '2px'}}/></Typography>
              </div>
            ):null}
            {isOpenFoodFact ? (
              <>
              {!!data.product && !!data.product.product_name_fr ? (
                <div style={{paddingRight: '0.5rem'}}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: 1.2, cursor: 'pointer' }} onClick={toggleAccordion}>{data.product.product_name_fr}</Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 500, lineHeight: 1.2, cursor: 'pointer' }} color="text.secondary" onClick={toggleAccordion}>{data.product.brands} {data.barcode}</Typography>
                  <Typography sx={{ fontSize: 12 }} color="text.secondary">Quantité : <Chip label={data.g + 'g'} variant="outlined" size="small" sx={{paddingTop: '2px',paddingBottom: '2px'}} /> {data.product.quantity}</Typography>
                </div>
              ):(
                <div style={{paddingRight: '0.5rem'}}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: 1.2,fontStyle: 'italic',whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={toggleAccordion}>Non référencé</Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 500, lineHeight: 1.2,fontStyle: 'italic', cursor: 'pointer' }} color="text.secondary" gutterBottom onClick={toggleAccordion}>{data.barcode}</Typography>
                  <Typography sx={{ fontSize: 12 }} color="text.secondary">Quantité : <Chip label={data.g + 'g'} variant="outlined" size="small" sx={{paddingTop: '2px',paddingBottom: '2px'}}/></Typography>
                </div>
              )} 
              </>
            ):null}
          </div>
        </div>
      </ListItem>
      <Collapse in={details} timeout="auto" unmountOnExit>
        <div>
          {isCiqual && !!data.product ? (
            <div style={{display: 'flex'}}>
              <div style={{width:'72px',textAlign:'center'}}>
                {isBarcodeInFavorites ? (
                  <IconButton color="primary" aria-label="Ajouter aux favoris" onClick={addToFavorites}>
                    <FavoriteBorderIcon />
                  </IconButton>
                ):(
                  <IconButton color="primary" aria-label="Retirer des favoris" onClick={removeFromFavorites}>
                    <FavoriteIcon />
                  </IconButton>
                )}
              </div>
              <div style={{width:'calc( 100% - 72px )'}}>
                <DataNutrimentLine 
                  label={'Energie'} 
                  valueRelative={S_Ciqual.formatValue(data.product['Energie, Règlement UE N° 1169/2011 (kcal/100 g)'])+' kcal/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(S_Ciqual.formatValue(data.product['Energie, Règlement UE N° 1169/2011 (kcal/100 g)']) * data.g / 100)) + 'kcal' : ''} />
                <DataNutrimentLine 
                  label={'Protéines'} 
                  valueRelative={S_Ciqual.formatValue(data.product['Protéines, N x 6.25 (g/100 g)'])+' kcal/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(S_Ciqual.formatValue(data.product['Protéines, N x 6.25 (g/100 g)']) * data.g / 100)) + 'kcal' : ''} />
                <DataNutrimentLine 
                  label={'Sucres'} 
                  valueRelative={S_Ciqual.formatValue(data.product['Sucres (g/100 g)'])+' kcal/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(S_Ciqual.formatValue(data.product['Sucres (g/100 g)']) * data.g / 100)) + 'kcal' : ''} />
                <DataNutrimentLine 
                  label={'Matières grasses'} 
                  valueRelative={S_Ciqual.formatValue(data.product['Lipides (g/100 g)'])+' kcal/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(S_Ciqual.formatValue(data.product['Lipides (g/100 g)']) * data.g / 100)) + 'kcal' : ''} />
                <DataNutrimentLine 
                  label={'Sel'} 
                  valueRelative={S_Ciqual.formatValue(data.product['Sel chlorure de sodium (g/100 g)'])+' kcal/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(S_Ciqual.formatValue(data.product['Sel chlorure de sodium (g/100 g)']) * data.g / 100)) + 'kcal' : ''} />
                <DataNutrimentLine 
                  label={'Fibres'} 
                  valueRelative={S_Ciqual.formatValue(data.product['Fibres alimentaires (g/100 g)'])+' kcal/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(S_Ciqual.formatValue(data.product['Fibres alimentaires (g/100 g)']) * data.g / 100)) + 'kcal' : ''} />
              </div>
            </div>
          ):null}
          {isOpenFoodFact && !!data.product && !!data.product.nutriments ? (
            <div style={{display: 'flex'}}>
              <div style={{width:'72px',textAlign:'center'}}>
                {isBarcodeInFavorites ? (
                  <IconButton color="primary" aria-label="Ajouter aux favoris" onClick={addToFavorites}>
                    <FavoriteBorderIcon />
                  </IconButton>
                ):(
                  <IconButton color="primary" aria-label="Retirer des favoris" onClick={removeFromFavorites}>
                    <FavoriteIcon />
                  </IconButton>
                )}
              </div>
              <div style={{width:'calc( 100% - 72px )'}}>
                <DataNutrimentLine 
                  label={'Energie'} 
                  valueRelative={data.product.nutriments['energy-kcal_100g']+' kcal/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(data.product.nutriments['energy-kcal_100g'] * data.g / 100)) + 'kcal' : ''} />
                <DataNutrimentLine 
                  label={'Protéines'} 
                  valueRelative={data.product.nutriments['proteins_100g']+' g/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(data.product.nutriments['proteins_100g'] * data.g / 100)) + 'g' : ''} />
                <DataNutrimentLine 
                  label={'Sucres'} 
                  valueRelative={data.product.nutriments['sugars_100g']+' g/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(data.product.nutriments['sugars_100g'] * data.g / 100)) + 'g' : ''} />
                <DataNutrimentLine 
                  label={'Matières grasses'} 
                  valueRelative={data.product.nutriments['fat_100g']+' g/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(data.product.nutriments['fat_100g'] * data.g / 100)) + 'g' : ''} />
                <DataNutrimentLine 
                  label={'Sel'} 
                  valueRelative={data.product.nutriments['salt_100g']+' g/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(data.product.nutriments['salt_100g'] * data.g / 100)) + 'g' : ''} />
                <DataNutrimentLine 
                  label={'Fibres'} 
                  valueRelative={data.product.nutriments['fiber_100g']+' g/100g'} 
                  valueEated={data.g !== undefined && data.g > 0 ? (Math.round(data.product.nutriments['fiber_100g'] * data.g / 100)) + 'g' : ''} />
                
              </div>
            </div>
          ):null}
        </div>
      </Collapse>
    </div>
  );
}

export default Data;
