import React,{useState,useEffect} from 'react';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Typography from '@mui/material/Typography';

import { Version } from 'react-mui-pwa-tools';

import S_Date        from './services/S_Date';

import DialogAdd  from './metier/ui/dialogs/DialogAdd';
import Day        from './metier/ui/molecules/Day';

import S_Datas       from './metier/services/S_Datas';
import DataClass     from './metier/services/DataClass';
import S_Favorites   from './metier/services/S_Favorites';
import S_Ciqual      from './metier/services/S_Ciqual';

import pkg        from '../package.json';

import './App.scss';

function App() {


  const [fetching, setFetching] = useState(false);
  const [datasByDays, setDatasByDays] = useState({});
  const [days, setDays] = useState([]);

  const [isDialogAddOpened, setIsDialogAddOpened] = useState(false);
  const [isSnackbarOpened, setIsSnackbarOpened] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // chargement des donnees
  useEffect(() => {
    if(!fetching){
      setFetching(true);
      S_Ciqual.fetch(() => {
        S_Favorites.fetch(() => {
          S_Favorites.fetchFromBarcodes(() => {
            S_Datas.fetch(() => {
              const DAYS = Object.keys(S_Datas.datasByDays);
              // console.log('DAYS',DAYS);
        
              DAYS.map((dayInString) => {
                const DAY = parseInt(dayInString);
                fetchProductsOfDay(DAY, () => {
                  // console.log('datasByDays',S_Datas.datasByDays);
                  setDatasByDays(S_Datas.datasByDays);
                  setDays(DAYS);
                  setFetching(false);
                });
              });
              
            });
          });
        });
      });
      
    }
  }, []);

  function fetchProductsOfDay(day10HInMillis:number,cb) {
    const datasOfDay = S_Datas.datasByDays[day10HInMillis];
    if(datasOfDay.length === 0){
      if(cb) cb();
      return;
    }
    fetchRecursive(datasOfDay,0,cb)
  }
  function fetchRecursive(datasOfDay,i,cb){
    const COUNT = datasOfDay.length;
    let ELEMENT = datasOfDay[i];
    if(ELEMENT.barcode!==undefined){
      ELEMENT.fetchFromBarcode(false,(product) => {
        if(!product){
          // si c le dernier
          if(i === (COUNT-1)){
            console.log('fetchRecursive c est le dernier (sans product), callback...');
            if(cb) cb();
          }else{
            fetchRecursive(datasOfDay,(i+1),cb);
          }
        }else{
          S_Datas.update(product,'product',ELEMENT,() => {
            // si c le dernier
            if(i === (COUNT-1)){
              console.log('fetchRecursive c est le dernier (avec product), callback...');
              if(cb) cb();
            }else{
              fetchRecursive(datasOfDay,(i+1),cb);
            }
          });
        }
      });
    }
    if(ELEMENT.ciqualcode!==undefined){
      console.log('fetchRecursive, element.ciqualcode',ELEMENT.ciqualcode);
      ELEMENT.fetchFromCiqualcode((product) => {
        if(!product){
          // si c le dernier
          if(i === (COUNT-1)){
            console.log('fetchRecursive c est le dernier (sans product), callback...');
            if(cb) cb();
          }else{
            fetchRecursive(datasOfDay,(i+1),cb);
          }
        }else{
          S_Datas.update(product,'product',ELEMENT,() => {
            // si c le dernier
            if(i === (COUNT-1)){
              console.log('fetchRecursive c est le dernier (avec product), callback...');
              if(cb) cb();
            }else{
              fetchRecursive(datasOfDay,(i+1),cb);
            }
          });
        }
      });
    }
  }




  // ----------------

  function add(){
    setIsDialogAddOpened(true);
  }
  function addDataValidate(dateInMillis:number, barcode:number, ciqualcode:number, g:number, ml:number, portion:number){
    S_Datas.add(dateInMillis, barcode, ciqualcode, g, ml, portion, (element) => {
      if(element.barcode!==undefined){
        element.fetchFromBarcode(false,(product) => {
          if(!product){
            setDatasByDays(S_Datas.datasByDays);
            // console.log('datasByDays',S_Datas.datasByDays);
            setDays(Object.keys(S_Datas.datasByDays));
            // console.log('days',Object.keys(S_Datas.datasByDays));
            setIsDialogAddOpened(false);
          }else{
            S_Datas.update(product,'product',element,() => {
              setDatasByDays(S_Datas.datasByDays);
              // console.log('datasByDays',S_Datas.datasByDays);
              setDays(Object.keys(S_Datas.datasByDays));
              // console.log('days',Object.keys(S_Datas.datasByDays));
              setIsDialogAddOpened(false);
            });
          }
        });
      }
      if(element.ciqualcode!==undefined){
        console.log('addDataValidate, element.ciqualcode',element.ciqualcode);
        element.fetchFromCiqualcode((product) => {
          if(!product){
            setDatasByDays(S_Datas.datasByDays);
            // console.log('datasByDays',S_Datas.datasByDays);
            setDays(Object.keys(S_Datas.datasByDays));
            // console.log('days',Object.keys(S_Datas.datasByDays));
            setIsDialogAddOpened(false);
          }else{
            S_Datas.update(product,'product',element,() => {
              setDatasByDays(S_Datas.datasByDays);
              // console.log('datasByDays',S_Datas.datasByDays);
              setDays(Object.keys(S_Datas.datasByDays));
              // console.log('days',Object.keys(S_Datas.datasByDays));
              setIsDialogAddOpened(false);
            });
          }
        });
      }
    });
  }
  function deleteData(data:DataClass){
    S_Datas.delete(data,() => {
      setDatasByDays(S_Datas.datasByDays);
      // console.log('datasByDays',S_Datas.datasByDays);
      setDays(Object.keys(S_Datas.datasByDays));
      // console.log('days',Object.keys(S_Datas.datasByDays));
    });
  }

  // ----------------

  
  function sumOfDay(day10HInMillis:number) {
    const datasOfDay = datasByDays[day10HInMillis];

    let nutriments = {
      energie: 0,
      fiber: 0,
      proteins: 0,
      sugars: 0,
      fat: 0,
      salt: 0
    };
    datasOfDay.forEach((element) => {
      // openfoodfact
      if(element.barcode!==undefined && !!element.product && !!element.product.nutriments){
        if(element.product.nutriments['energy-kcal_100g']!==undefined && element.g!==undefined){
          nutriments.energie += element.product.nutriments['energy-kcal_100g'] * element.g / 100;
        }
        if(element.product.nutriments['proteins_100g']!==undefined && element.g!==undefined){
          nutriments.proteins += element.product.nutriments['proteins_100g'] * element.g / 100;
        }
        if(element.product.nutriments['sugars_100g']!==undefined && element.g!==undefined){
          nutriments.sugars += element.product.nutriments['sugars_100g'] * element.g / 100;
        }
        if(element.product.nutriments['fat_100g']!==undefined && element.g!==undefined){
          nutriments.fat += element.product.nutriments['fat_100g'] * element.g / 100;
        }
        if(element.product.nutriments['salt_100g']!==undefined && element.g!==undefined){
          nutriments.salt += element.product.nutriments['salt_100g'] * element.g / 100;
        }
        if(element.product.nutriments['fiber_100g']!==undefined && element.g!==undefined){
          nutriments.fiber += element.product.nutriments['fiber_100g'] * element.g / 100;
        }
      }
      // ciqual
      if(element.ciqualcode!==undefined && !!element.product){
        let ref = element.product['Energie, Règlement UE N° 1169/2011 (kcal/100 g)'];
        if(!!ref && ref!=='traces' && !!element.g){
          nutriments.energie += S_Ciqual.formatValue(ref) * element.g / 100;
        }
        ref = element.product['Protéines, N x 6.25 (g/100 g)'];
        if(ref && ref!=='traces' && !!element.g){
          nutriments.proteins += S_Ciqual.formatValue(ref) * element.g / 100;
        }
        ref = element.product['Sucres (g/100 g)'];
        if(!!ref && ref!=='traces' && !!element.g){
          nutriments.sugars += S_Ciqual.formatValue(ref) * element.g / 100;
        }
        ref = element.product['Lipides (g/100 g)'];
        if(!!ref && ref!=='traces' && !!element.g){
          nutriments.fat += S_Ciqual.formatValue(ref) * element.g / 100;
        }
        ref = element.product['Sel chlorure de sodium (g/100 g)'];
        if(!!ref && ref!=='traces' && !!element.g){
          nutriments.salt += S_Ciqual.formatValue(ref) * element.g / 100;
        }
        ref = element.product['Fibres alimentaires (g/100 g)'];
        if(!!ref && ref!=='traces' && !!element.g){
          nutriments.fiber += S_Ciqual.formatValue(element.product['Fibres alimentaires (g/100 g)']) * element.g / 100;
        }
      }
    });


    return {
      datas: datasOfDay,
      nutriments: nutriments
    };
  }


  return (
    <div>

      <div>
        <div style={{textAlign: 'center',margin:'0.5rem'}}>
          <Button variant="outlined" onClick={add} color="primary">Ajouter</Button>
        </div>

        {days.map((dayInString,index) => {
          const day = parseInt(dayInString);
          const sum = sumOfDay(day);
          return (
            <Day key={index} day={day} sum={sum} deleteData={deleteData}/>
          );
        })}

      </div>

      <div>
        <Version 
          development={!!process.env && process.env.NODE_ENV === 'development'}
          version={pkg.version}
          lastUpdate={pkg.lastUpdate}/>
      </div>

      <DialogAdd
        isDialogOpened={isDialogAddOpened}
        closeDialog={(e) => setIsDialogAddOpened(false)}
        onClickValidate={addDataValidate} />
    </div>
  );
}

export default App;
