import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// Composants génériques
import S_App      from '../../../services/S_App';

// Composants propres
import DataClass        from '../../services/DataClass';
import S_Favorites      from '../../services/S_Favorites';
import S_Ciqual         from '../../services/S_Ciqual';

// Styles
// import './DialogAdd.scss';

type DialogAddProps = {
  isDialogOpened: boolean;
  closeDialog: any;

  onClickValidate: any;
}

function DialogAdd(props:DialogAddProps) {

  // props
  let {isDialogOpened,closeDialog,onClickValidate} = props;

  // state
  const [barcode, setBarcode] = useState(undefined);
  const [ciqualcode, setCiqualcode] = useState(undefined);
  const [label, setLabel] = useState('');
  const [g, setG] = useState(0);
  const [product, setProduct] = useState(undefined);
  const [isBarcodeInFavorites, setIsBarcodeInFavorites] = useState(false);
  const [suggestionsFavorites, setSuggestionsFavorites] = useState([]);
  const [suggestionsCiqual, setSuggestionsCiqual] = useState([]);
  
  function changeBarcode(value){
    setBarcode(value);
    setCiqualcode(undefined);
    fetchFromBarcode(value);
    const barcodeAsNumber = typeof value === 'number' ? value : parseInt(value);
    setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(barcodeAsNumber)===-1);
  }
  function changeCiqualcode(values){
    setBarcode(undefined);
    setCiqualcode(values.alim_code);
    setProduct(values);
    const ciqualcodeAsNumber = typeof values.alim_code === 'number' ? values.alim_code : parseInt(values.alim_code);
    setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(ciqualcodeAsNumber)===-1);
  }
  function changeLabel(value){
    setLabel(value);
    searchFromLabel(value);
  }
  function changeG(value){
    setG(value);
  }

  function fetchFromBarcode(searchedBarcode) {
    if(searchedBarcode === undefined || searchedBarcode.length<13){
      setProduct(undefined);
      return null;
    }

    // energie (kcal)
    // matieres grasses (g)
    // glucides (g)
    // proteines (g)
    // fibres (g)
    // sel (g)

    const API_URL = `https://world.openfoodfacts.org/api/v0/product/${searchedBarcode}.json`;
    if(S_App.isLogDebug) console.log('API_URL',API_URL);

    fetch(API_URL).then(function(v) {
      v.json().then(function(jsonDatas) {
        if(S_App.isLogDebug) console.log('result',jsonDatas);
        if(jsonDatas.status !== 1){
          setProduct(undefined);
          return null;
        }
        const product = jsonDatas.product;
        if(S_App.isLogDebug) console.log('product',product);

        setProduct(product);
      });
    });

  }

  function searchFromLabel(label) {
    if(label === undefined || label.length<4){
      
      return null;
    }

    // recherche sur openfoodfacts ...

    // const API_URL = `https://fr.openfoodfacts.org/cgi/search.pl?action=process&tagtype_0=product_name_fr&tag_contains_0=contains&tag_0=${label}&json=true`;
    // if(S_App.isLogDebug) console.log('API_URL',API_URL);

    // fetch(API_URL).then(function(v) {
    //   v.json().then(function(jsonDatas) {
    //     if(S_App.isLogDebug) console.log('result',jsonDatas);
    //     if(jsonDatas.status !== 1){
          
    //       return null;
    //     }
    //     // const product = jsonDatas.product;
    //     // if(S_App.isLogDebug) console.log('product',product);
    //   });
    // });

    // recherche sur ciqual ...
    if(S_App.isLogDebug) console.log('searchFromLabel() in ciqual',label,S_Ciqual.ciquals);
    const ciqual_suggestions = S_Ciqual.ciquals.filter(element => {
      return element.alim_nom_fr.trim().toLowerCase().indexOf(label.trim().toLowerCase())!==-1;
    });
    if(S_App.isLogDebug) console.log('ciqual_suggestions',ciqual_suggestions);
    setSuggestionsCiqual([...ciqual_suggestions]);


    // recherche dans les favoris ...
    if(S_App.isLogDebug) console.log('searchFromLabel() in favorites',label,S_Favorites.favorites_barcodes_datas);
    const favorites_suggestions = S_Favorites.favorites_barcodes_datas.filter(element => {
      return element.product_name_fr.trim().toLowerCase().indexOf(label.trim().toLowerCase())!==-1 || 
        element.brands.trim().toLowerCase().indexOf(label.trim().toLowerCase())!==-1;
    });
    if(S_App.isLogDebug) console.log('favorites_suggestions',favorites_suggestions);
    setSuggestionsFavorites([...favorites_suggestions]);

    // recherche dans les deja utilises ...
  }
  function selectSuggestion(suggestion){
    changeBarcode(suggestion.code);
    setLabel('');
    setSuggestionsFavorites([]);
  }
  function selectSuggestionCiqual(suggestion){
    changeCiqualcode(suggestion);
    setLabel('');
    setSuggestionsCiqual([]);
  }

  function resetValues() {
    setBarcode(undefined);
    setCiqualcode(undefined);
    setLabel('');
    setG(0);
    setProduct(undefined);
    setIsBarcodeInFavorites(false);
    setSuggestionsFavorites([]);
    setSuggestionsCiqual([]);
  }

  function handleClose(){
    resetValues();
    closeDialog();
  }
  function handleValidate(){
    onClickValidate(undefined, parseInt(barcode), parseInt(ciqualcode), parseFloat(g+''), undefined, undefined);
    resetValues();
  }
  function addToFavorites(){
    if(barcode!==undefined){
      console.log('DialogAdd -- addToFavorites',barcode);
      const barcodeAsNumber = typeof barcode === 'number' ? barcode : parseInt(barcode);
      S_Favorites.add(barcodeAsNumber,() => {
        setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(barcodeAsNumber)===-1);
      });
    }
    if(ciqualcode!==undefined){
      console.log('DialogAdd -- addToFavorites',ciqualcode);
      const ciqualcodeAsNumber = typeof ciqualcode === 'number' ? ciqualcode : parseInt(ciqualcode);
      S_Favorites.add(ciqualcodeAsNumber,() => {
        setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(ciqualcodeAsNumber)===-1);
      });
    }
  }
  function removeFromFavorites(){
    if(barcode!==undefined){
      console.log('DialogAdd -- removeFromFavorites',barcode);
      const barcodeAsNumber = typeof barcode === 'number' ? barcode : parseInt(barcode);
      S_Favorites.delete(barcodeAsNumber,() => {
        setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(barcodeAsNumber)===-1);
      });
    }
    if(ciqualcode!==undefined){
      console.log('DialogAdd -- removeFromFavorites',ciqualcode);
      const ciqualcodeAsNumber = typeof ciqualcode === 'number' ? ciqualcode : parseInt(ciqualcode);
      S_Favorites.delete(ciqualcodeAsNumber,() => {
        setIsBarcodeInFavorites(S_Favorites.favorites_barcodes.indexOf(ciqualcodeAsNumber)===-1);
      });
    }
  }


  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus>
      <DialogTitle id="alert-dialog-title">J'ai mangé...</DialogTitle>
      <DialogContent>

        <div style={{marginBottom: '0.5rem',marginTop: '0.5rem'}}>
          <TextField id="barcode" label={'Code barre'} type="number" fullWidth size="small" autoFocus={true} InputLabelProps={{ shrink: true }}
            value={barcode}
            onChange={(e) => changeBarcode(e.target.value)} />
        </div>

        <div style={{marginBottom: '0.5rem',marginTop: '0.5rem'}}>
          <TextField id="label" label={'Rechercher'} type="string" fullWidth size="small" autoFocus={false} InputLabelProps={{ shrink: true }}
            value={label}
            onChange={(e) => changeLabel(e.target.value)} />
        </div>

        {!!suggestionsFavorites && suggestionsFavorites.length>0 ? (
        <List>
          {suggestionsFavorites.map((suggestion,index) => {
            if(!suggestion)
              return null;
            return (
              <ListItem key={index} onClick={() => selectSuggestion(suggestion)} style={{cursor:'pointer',padding: '0'}}>
                <ListItemAvatar>
                  {!!suggestion.image_small_url ? (
                    <Avatar alt="" src={`${suggestion.image_small_url}`} variant="rounded"/>
                  ):(
                    <Avatar variant="rounded"><QuestionMarkIcon /></Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={suggestion.product_name_fr} secondary={suggestion.brands + ' ' + suggestion.code} sx={{lineHeight: 1}}/>
              </ListItem>
            );
          })}
        </List>
        ):null}

        {!!suggestionsCiqual && suggestionsCiqual.length>0 ? (
        <List>
          {suggestionsCiqual.map((suggestion,index) => {
            if(!suggestion)
              return null;
            return (
              <ListItem key={index} onClick={() => selectSuggestionCiqual(suggestion)} style={{cursor:'pointer',padding: '0'}} disableGutters>
                <ListItemAvatar><Avatar variant="rounded"><QuestionMarkIcon /></Avatar></ListItemAvatar>
                <ListItemText primary={suggestion.alim_nom_fr} sx={{lineHeight: 1}}/>
              </ListItem>
            );
          })}
        </List>
        ):null}

        {!!product && barcode!==undefined ? (
          <div style={{textAlign:'center',backgroundColor:'#EFEFEF',borderRadius:'4px'}}>
            <Grid container>
              <Grid item xs={6} style={{textAlign:'center'}}>
                <img
                  src={`${product.image_small_url}`}
                  srcSet={`${product.image_small_url}`}
                  alt={''}
                  loading="lazy"
                  style={{maxWidth:'calc( 100% - 1rem )',borderRadius:'4px',margin:'0.5rem'}} />
              </Grid>
              <Grid item xs={6}>
                {isBarcodeInFavorites ? (
                  <IconButton color="primary" aria-label="Ajouter aux favoris" onClick={addToFavorites}>
                    <FavoriteBorderIcon />
                  </IconButton>
                ):(
                  <IconButton color="primary" aria-label="Retirer des favoris" onClick={removeFromFavorites}>
                    <FavoriteIcon />
                  </IconButton>
                )}
                <Typography sx={{ fontSize: 16 }} variant="h5" component="div">{product.brands}</Typography>
                <Typography sx={{ fontSize: 14,lineHeight: 1 }} color="text.secondary" gutterBottom>{product.product_name_fr}</Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{product.quantity}</Typography>
              </Grid>
            </Grid>
          </div>
        ):null}

        {!!product && ciqualcode!==undefined ? (
          <div style={{textAlign:'center',backgroundColor:'#EFEFEF',borderRadius:'4px'}}>
            <Grid container>
              <Grid item xs={12}>
                {isBarcodeInFavorites ? (
                  <IconButton color="primary" aria-label="Ajouter aux favoris" onClick={addToFavorites}>
                    <FavoriteBorderIcon />
                  </IconButton>
                ):(
                  <IconButton color="primary" aria-label="Retirer des favoris" onClick={removeFromFavorites}>
                    <FavoriteIcon />
                  </IconButton>
                )}
                <Typography sx={{ fontSize: 14,lineHeight: 1 }} color="text.secondary" gutterBottom>{product.alim_nom_fr}</Typography>
              </Grid>
            </Grid>
          </div>
        ):null}

        <br/>

        <div style={{marginBottom: '0.5rem',marginTop: '0.5rem'}}>
          <TextField id="g" label={'Quantité (g)'} type="number" fullWidth size="small" autoFocus={false} InputLabelProps={{ shrink: true }}
            value={g}
            onChange={(e) => changeG(e.target.value)} />
        </div>

        

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Annuler</Button>
        <Button onClick={handleValidate} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogAdd;
