import S_App          from   '../../services/S_App';
import S_Ciqual       from   './S_Ciqual';

interface I_S_Favorites {
  STORAGE_KEY_FAVORITES_BARCODES: string;
  favorites_barcodes: number[];
  favorites_barcodes_datas: any[];

  fetch(cb): any;
  store(cb): any;
  
  add(barcode:number,cb): void;
  delete(favorite:number,cb): void;

  fetchFromBarcodes(cb):void;
};

/**
 * @namespace
 * @folder Services
 * @type {Object}
 */
let S_Favorites: I_S_Favorites = {
  /**
   * @memberof S_Favorites
   * @property {string} STORAGE_KEY_FAVORITES_BARCODES Clé de sauvegarde en localStorage des datas
   */
   STORAGE_KEY_FAVORITES_BARCODES : 'favorites_barcodes',

  /** Liste des barcodes */
  favorites_barcodes : [],
  /** Liste des object openfoodfacts */
  favorites_barcodes_datas : [],

  /**
   * Récupère les données en localStorage, et contruit la liste des barcodes
   * @param cb Fonction de callback
   */
  fetch: (cb) => {

    // reset
    S_Favorites.favorites_barcodes = [];
  
    // localStorage
    let datasBruts = [];
    const locSto1 = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Favorites.STORAGE_KEY_FAVORITES_BARCODES];
    if(locSto1){
      datasBruts = JSON.parse(locSto1);
      if(S_App.isLogDebug) console.log('-- S_Favorites.tsx -- fetch() datas bruts',datasBruts);
    }

    // Ajout
    datasBruts.forEach(element => {
      const id = element;
      let barcode = typeof element !== 'number' ? parseInt(element) : element;
      if(S_Favorites.favorites_barcodes.indexOf(barcode)===-1)
        S_Favorites.favorites_barcodes.push(barcode);
    });
    if(S_App.isLogDebug) console.log('-- S_Favorites.tsx -- fetch() array',S_Favorites.favorites_barcodes);

    if(!!cb) cb();
  },

  /**
   * Enregistre les donnees en localStorage
   * @param cb Fonction de callback
   */
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Favorites.STORAGE_KEY_FAVORITES_BARCODES] = JSON.stringify(S_Favorites.favorites_barcodes);
    if(cb)
      cb();
  },

  /**
   * Ajoute un favori
   * @param cb Fonction callback
   * @returns {DataClass}
   */
  add(barcode:number,cb):void{

    if(barcode === undefined){
      if(cb) cb(undefined);
      return;
    }

    if(!S_Favorites.favorites_barcodes)
      S_Favorites.favorites_barcodes = [];

    if(S_Favorites.favorites_barcodes.indexOf(barcode)===-1)
      S_Favorites.favorites_barcodes.push(barcode);
    
    S_Favorites.store(() => {
      if(cb) cb(barcode);
    });
  },
  /**
   * Supprime le favori
   * @param barcode à supprimer
   * @param cb Fonction callback
   */
  delete(barcode:number,cb):void{
    if(!S_Favorites.favorites_barcodes || S_Favorites.favorites_barcodes.length===0 || barcode===undefined){
      if(cb) cb();
      return;
    }

    const index = S_Favorites.favorites_barcodes.indexOf(barcode);
    if(index===-1){
      if(S_App.isLogDebug) console.log('-- S_Favorites.tsx -- delete() trying to remove a barcode not is favorites',S_Favorites.favorites_barcodes);
      if(cb) cb();
      return;
    }
    S_Favorites.favorites_barcodes.splice(index,1);

    S_Favorites.store(cb);
  },

  /** Récupère les infos nutritionelles à partir du code bare */
  fetchFromBarcodes(cb) {

    S_Favorites.favorites_barcodes_datas = [];

    let received = 0;
    for(var i = 0; i < S_Favorites.favorites_barcodes.length; i++){
      const barcode = S_Favorites.favorites_barcodes[i];

      if(barcode>100000){
        const ID = barcode;
        const API_URL = `https://world.openfoodfacts.org/api/v0/product/${ID}.json`;
        if(S_App.isLogDebug) console.log('API_URL',API_URL);

        fetch(API_URL).then(function(v) {
          v.json().then(function(jsonDatas) {
            if(S_App.isLogDebug) console.log('OpenFoodFacts result',jsonDatas);
            received++;
            if(jsonDatas.status !== 1){
              
            }else{
              const product = jsonDatas.product;
              if(S_App.isLogDebug) console.log('OpenFoodFacts product',product);
    
              S_Favorites.favorites_barcodes_datas.push(product);
              if(received === S_Favorites.favorites_barcodes.length){
                if(cb) cb();
              }
              
            }
          });
        });
      }else{
        received++;
      }
    }

  }
};
export default S_Favorites;