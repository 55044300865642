import S_App from "../../services/S_App";
import S_Date from "../../services/S_Date";
import S_Ciqual from "./S_Ciqual";

export interface I_Data {
  id: number;
  date: number;

  barcode?: number;
  ciqualcode?: number;

  g?: number;   // grammes
  ml?: number;  // millilitres
  portion?: number; // 1/4 1/6

  product?: any;  // resultats de la requete world.openfoodfacts.org
};

/**
 * Represente une data.
 * 
 * 
 * @folder Services
 */
class DataClass {

  /** 
   * Identifiant
   * @type number
   */   
  id: number;
  /**
   * Date et heure
   * @type number
   */
  date: number;

  barcode: number;
  ciqualcode: number;

  g: number;   // grammes
  ml: number;  // millilitres
  portion: number; // 1/4 1/6

  product: any;
  
  /**
   * Constructeur
   * @param {I_Data} elementBrut
   */
  constructor(elementBrut:I_Data) {
    this.id = elementBrut.id;
    this.date = elementBrut.date;
    this.barcode = typeof elementBrut.barcode === 'string' ? parseInt(elementBrut.barcode) : elementBrut.barcode;
    this.ciqualcode = typeof elementBrut.ciqualcode === 'string' ? parseInt(elementBrut.ciqualcode) : elementBrut.ciqualcode;
    this.g = elementBrut.g;
    this.ml = elementBrut.ml;
    this.portion = elementBrut.portion;

    this.product = undefined;
  }

  toJson() {
    return {
      id: this.id,
      date: this.date,
      barcode : this.barcode,
      ciqualcode : this.ciqualcode,
      g : this.g,
      ml : this.ml,
      portion : this.portion
    };
  }


  toBrutJson() {
    return {
      id: this.id,
      date: this.date,
      barcode : this.barcode,
      ciqualcode : this.ciqualcode,
      g : this.g,
      ml : this.ml,
      portion : this.portion
    };
  }

  /** Renvoie le timeInMillis du jour */
  getDay10HInMillis() {
    return S_Date.getDay10HInMillis(this.date);
  }

  /** Récupère les infos nutritionelles à partir du code bare */
  fetchFromBarcode(force:boolean,cb) {
    if(this.barcode === undefined){
      if(!!cb) cb(undefined);
    }
    if(!force && !!this.product) {
      // on l'a deja fetch, on skip
      if(!!cb) cb(undefined);
    }
    
    const ID = this.barcode;
    const API_URL = `https://world.openfoodfacts.org/api/v0/product/${ID}.json`;
    if(S_App.isLogDebug) console.log('API_URL',API_URL);

    fetch(API_URL).then(function(v) {
      v.json().then(function(jsonDatas) {
        // if(S_App.isLogDebug) console.log('OpenFoodFacts result',jsonDatas);
        if(jsonDatas.status !== 1){
          if(!!cb) cb(undefined);
        }
        const product = jsonDatas.product;
        // if(S_App.isLogDebug) console.log('OpenFoodFacts product',product);

        if(!!cb) cb(product);
      });
    });

  }

  /** Récupère les infos nutritionelles à partir de l'id ciqual */
  fetchFromCiqualcode(cb) {
    if(this.ciqualcode === undefined){
      // if(S_App.isLogDebug) console.log('fetchFromCiqualcode but ciqualcode is undefined');
      if(!!cb) cb(undefined);
    }

    const ciqual = S_Ciqual.ciquals.filter(element => {
      return parseInt(element.alim_code) === this.ciqualcode;
    });
    if(S_App.isLogDebug) console.log('fetchFromCiqualcode found',ciqual[0]);

    if(!!cb) cb(ciqual[0]);
  }
  
}

export default DataClass;
