import React,{useState,useEffect} from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import S_Date        from '../../../services/S_Date';
import S_Favorites   from '../../services/S_Favorites';
import Data          from './Data';
import DayNutrimentLine          from '../atoms/DayNutrimentLine';

// import './Day.scss';

type DayProps = {
  day: number;      // dateInMillis
  sum: any;      // infos

  deleteData: any;
}

function Day(props:DayProps) {
  // props
  let {day,sum,deleteData} = props;
  // states

  

  return (
    <div style={{marginBottom:'0.5rem'}}>
      <Card sx={{ minWidth: 275 }} style={{marginLeft:'0.5rem',marginRight:'0.5rem'}}>
        <CardHeader title={S_Date.formatDateInStringWithName(day)}>
        </CardHeader>
        <CardContent>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              {sum.nutriments ? (
                <div>
                  <DayNutrimentLine 
                    label={'Energie'} 
                    valueEated={Math.round(sum.nutriments.energie)} 
                    valueRecommended={2000}
                    valueEatedString={Math.round(sum.nutriments.energie)+'kcal'} 
                    valueRecommendedString={'2000kcal'}/>
                  <DayNutrimentLine 
                    label={'Protéines'} 
                    valueEated={Math.round(sum.nutriments.proteins)} 
                    valueRecommended={50}
                    valueEatedString={Math.round(sum.nutriments.proteins)+'g'} 
                    valueRecommendedString={'50g'}/>
                  <DayNutrimentLine 
                    label={'Sucres'} 
                    valueEated={Math.round(sum.nutriments.sugars)} 
                    valueRecommended={90}
                    valueEatedString={Math.round(sum.nutriments.sugars)+'g'} 
                    valueRecommendedString={'90g'}/>
                  <DayNutrimentLine 
                    label={'Matières grasses'} 
                    valueEated={Math.round(sum.nutriments.fat)} 
                    valueRecommended={70}
                    valueEatedString={Math.round(sum.nutriments.fat)+'g'} 
                    valueRecommendedString={'70g'}/>
                  <DayNutrimentLine 
                    label={'Sel'} 
                    valueEated={Math.round(sum.nutriments.salt * 100) / 100} 
                    valueRecommended={6}
                    valueEatedString={Math.round(sum.nutriments.salt * 100) / 100+'g'} 
                    valueRecommendedString={'6g'}/>
                  <DayNutrimentLine 
                    label={'Fibres'} 
                    valueEated={Math.round(sum.nutriments.fiber)} 
                    valueRecommended={22}
                    valueEatedString={Math.round(sum.nutriments.fiber)+'g'} 
                    valueRecommendedString={'22g'}/>
                </div>
              ):null}
            </Grid>
            <Grid item xs={12} lg={6}>
              <List>
              {sum.datas.map((data) => {
                return (
                  <Data key={data.id} 
                    data={data}
                    deleteData={deleteData}/>
                );
              })}
              </List>
            </Grid>
          </Grid>

        </CardContent>
        <CardActions>
          
        </CardActions>
      </Card>
    </div>
  );
}

export default Day;
