import S_App          from   '../../services/S_App';
import S_Date         from   '../../services/S_Date';
import DataClass from   './DataClass';

interface I_S_Datas {
  STORAGE_KEY_DATAS: string;
  datas: DataClass[];
  datasByDays: any;

  fetch(cb): any;
  store(cb): any;

  getById(idData:number|string): DataClass;
  
  add(dateInMillis:undefined|number,
    barcode:undefined|number,
    ciqualcode:undefined|number,
    g:undefined|number,
    ml:undefined|number,
    portion:undefined|number,cb): void;
  update(value:undefined|number|string,field:string,data:DataClass,cb): void;
  delete(data:DataClass,cb): void;

  getDatasOfDay(day10HInMillis:number): DataClass[];
};

/**
 * @namespace
 * @folder Services
 * @type {Object}
 */
let S_Datas: I_S_Datas = {
  /**
   * @memberof S_Datas
   * @property {string} STORAGE_KEY_DATAS Clé de sauvegarde en localStorage des datas
   */
  STORAGE_KEY_DATAS : 'datas',

  /** Liste des instances de DataClass */
  datas : [],

  /** Liste triée et regroupée par date (même jour) */
  datasByDays : [],

  /**
   * Récupère les données en localStorage, et contruit la liste des instances de DataClass
   * @param cb Fonction de callback
   */
  fetch: (cb) => {
    // if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch()...');

    // reset
    S_Datas.datas = [];
    S_Datas.datasByDays = [];
  
    // localStorage
    let datasBruts = [];
    const locSto1 = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_DATAS];
    if(locSto1){
      datasBruts = JSON.parse(locSto1);
      if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fetch() datas bruts',datasBruts);
    }

    // Creation des instances de DataClass
    datasBruts.forEach(element => {
      const id = element.id;
      let obj = new DataClass(element);
      S_Datas.datas.push(obj);
    });
    if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fetch() datas (classes)',S_Datas.datas);

    // Regroupement par jour
    S_Datas.datasByDays = S_Date.groupByDay(S_Datas.datas);
    if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fetch() datas by days',S_Datas.datasByDays);

    if(!!cb) cb();
  },

  /**
   * Enregistre les donnees en localStorage
   * @param cb Fonction de callback
   */
  store: (cb) => {
    let datasBruts = [];
    if(!!S_Datas.datas){
      S_Datas.datas.forEach(element => {
        datasBruts.push(element.toBrutJson());
      });
    }
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_DATAS] = JSON.stringify(datasBruts);
    if(cb)
      cb();
  },

  /**
   * Renvoi l'objet correspondant au parametre
   * @param id ID de la référence
   * @returns {DataClass}
   */
  getById: (id:number|string) : DataClass => {
    const matchingDataClasses = S_Datas.datas.filter(element => element.id === id);
    return !!matchingDataClasses && matchingDataClasses.length>0 ? matchingDataClasses[0] : undefined;
  },

  /**
   * Ajoute une reference
   * @param cb Fonction callback
   * @returns {DataClass}
   */
  add(dateInMillis:number,
    barcode:undefined|number,
    ciqualcode:undefined|number,
    g:undefined|number,
    ml:undefined|number,
    portion:undefined|number,cb):void{
      
    const dateNow = (new Date()).getTime();
    const dateToStore = !!dateInMillis ? dateInMillis : dateNow;
    const newRefObject = {
      id: dateNow,
      date: dateToStore,
      barcode : barcode,
      ciqualcode : ciqualcode,
      g : g,
      ml : ml,
      portion : portion
    };
    const newDataClass = new DataClass(newRefObject);

    if(!S_Datas.datas)
    S_Datas.datas = [];
    S_Datas.datas.push(newDataClass);

    S_Datas.datasByDays = S_Date.groupByDay(S_Datas.datas);
    
    S_Datas.store(() => {
      if(cb) cb(newDataClass);
    });
  },
  /**
   * 
   * @param value nouvelle valeur a enregistrer pour l'attribut
   * @param field nom de l'attribut a modifier
   * @param ingredient Ingredient à modifier, instance d'IngredientClass
   * @param cb Fonction callback
   * @returns {IngredientClass}
   */
  update(value:any,field:string,data:DataClass,cb):void{
    if(!S_Datas.datas || S_Datas.datas.length===0 || !data){
      if(cb) cb();
      return;
    }
    
    let matchings = S_Datas.datas.filter(element => element.id === data.id);
    if(!matchings || matchings.length === 0){
      console.log('Cannot update data, id',data.id+'not found.');
      return;
    }

    matchings[0][field] = value;

    S_Datas.datasByDays = S_Date.groupByDay(S_Datas.datas);

    S_Datas.store(() => {
      if(cb) cb(matchings[0]);
    });
  },
  /**
   * Supprime la data
   * @param ingredient Data à supprimer, instance de DataClass
   * @param cb Fonction callback
   */
  delete(data:DataClass,cb){
    if(!S_Datas.datas || S_Datas.datas.length===0 || !data){
      if(cb) cb();
      return;
    }
    
    let matchingIndex = undefined;
    S_Datas.datas.forEach((element,index) => {
      if(element.id === data.id){
        matchingIndex = index;
      }
    });
    if(matchingIndex === -1){
      console.log('Cannot delete data, id',data.id+'not found.');
      return;
    }
    S_Datas.datas.splice(matchingIndex,1);

    S_Datas.datasByDays = S_Date.groupByDay(S_Datas.datas);

    S_Datas.store(cb);
  },


  // -----------------------------------------------

  getDatasOfDay(day10HInMillis:number) {
    return S_Datas.datasByDays[day10HInMillis];
  }

  
};
export default S_Datas;